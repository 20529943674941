// ** Icons Import
import { Heart } from 'react-feather';
// theme config import
import themeConfig from '@configs/themeConfig';

const Footer = () => {
    return (
        <p className="clearfix mb-0">
            <span className="float-md-start d-block d-md-inline-block mt-25">
                COPYRIGHT © {new Date().getFullYear()}
                &nbsp;
                <a href="/" target="_blank" rel="noopener noreferrer">
                    {themeConfig.app?.projectName}
                </a>
                <span className="d-none d-sm-inline-block">, All Rights Reserved</span>
            </span>
            <span className="float-md-end d-none d-md-block">
                Hand-crafted & Made with
                <Heart size={14} /> by Team FeelTech
            </span>
        </p>
    );
};

export default Footer;
