// ** React Imports
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

// ** Custom Components
import Avatar from '@components/avatar';

// ** Utils
import { isUserLoggedIn } from '@utils';

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux';
import { handleLogout } from '@store/authentication';

// ** Third Party Components
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power, Key } from 'react-feather';

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

// ** Default Avatar Image
import DefaultAvatar from '@src/assets/images/avatars/default.jpg';

// Service Constant Import
import service from '@src/service/constant';

// secure ls
import { secureGet } from '@src/service/secureLs';

const UserDropdown = () => {
    // ** Store Vars
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state?.auth);

    // ** State
    const [userData, setUserData] = useState(null);

    //** ComponentDidMount
    useEffect(() => {
        if (isUserLoggedIn() !== null) {
            // setUserData(JSON.parse(localStorage.getItem('userData')));
            setUserData(secureGet('userData'));
        }
    }, []);

    //** Vars
    const userAvatar = auth?.userData?.profileImage ? service.API_URL + '/' + auth?.userData?.profileImage : DefaultAvatar;

    return (
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={(e) => e.preventDefault()}>
                <div className="user-nav d-sm-flex d-none">
                    <span className="user-name fw-bold">{(auth?.userData && auth?.userData?.userName) || 'John Doe'}</span>
                    <span className="user-status">{(auth?.userData && auth?.userData?.role) || 'Admin'}</span>
                </div>
                <Avatar img={userAvatar} imgHeight="40" imgWidth="40" status="online" />
            </DropdownToggle>
            <DropdownMenu end>
                <DropdownItem
                    tag={Link}
                    to="/pages/profile"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/profile');
                    }}
                >
                    <User size={14} className="me-75" />
                    <span className="align-middle">Profile</span>
                </DropdownItem>{' '}
                <DropdownItem
                    tag={Link}
                    to="/pages/profile"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/profile/change-password');
                    }}
                >
                    <Key size={14} className="me-75" />
                    <span className="align-middle">Change Password</span>
                </DropdownItem>
                <DropdownItem divider />
                {/* <DropdownItem tag={Link}>
                    <Settings size={14} className="me-75" />
                    <span className="align-middle">Settings</span>
                </DropdownItem> */}
                <DropdownItem tag={Link} to="/login" onClick={() => dispatch(handleLogout())}>
                    <Power size={14} className="me-75" />
                    <span className="align-middle">Logout</span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default UserDropdown;
