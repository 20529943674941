import { lazy } from 'react';

const DataGrid = lazy(() => import('../../views/DataGrid'));

const TableRoutes = [
    {
        path: '/table',
        element: <DataGrid />
    }
];

export default TableRoutes;
