// ** React Imports
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

// ** Utils
import { isUserLoggedIn, getHomeRouteForLoggedInUser, is } from '@utils';

const PublicRoute = ({ children, route }) => {
    if (route) {
        const loggedIn = isUserLoggedIn();

        const restrictedRoute = route.meta && route.meta.restricted;

        if (loggedIn && restrictedRoute) {
            return <Navigate to={getHomeRouteForLoggedInUser()} />;
        }
    }

    return <Suspense fallback={null}>{children}</Suspense>;
};

export default PublicRoute;
