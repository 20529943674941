// ** Reducers Imports
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
// import roles from '../views/Setup/RoleRights/Role/store';
import institutes from '../views/Dashboard/store';

const rootReducer = {
    auth,
    layout,
    // permission,
    users,
    // roles
    institutes
};

export default rootReducer;
