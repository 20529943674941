// ** React Imports
import { Fragment } from 'react';

// ** Custom Components
import NavbarUser from './NavbarUser';
import NavbarBookmarks from './NavbarBookmarks';

const ThemeNavbar = (props) => {
    // ** Props
    const { skin, setSkin, setMenuVisibility } = props;

    return (
        <Fragment>
            <div className="bookmark-wrapper d-flex align-items-center">
                <NavbarBookmarks setMenuVisibility={setMenuVisibility} skin={skin} setSkin={setSkin} />
            </div>
            <NavbarUser skin={skin} setSkin={setSkin} />
        </Fragment>
    );
};

export default ThemeNavbar;
