import { lazy } from 'react';

// permission modules
import modules from '@src/service/modules';

const ModuleMaster = lazy(() => import('../../views/ModuleMaster'));

const ModulePagesRoutes = [
    {
        path: '/module',
        element: <ModuleMaster />
    }
];

export default ModulePagesRoutes;
