import { Circle, Tool } from 'react-feather';

// permission modules
import modules from '@src/service/modules';

export default [
    {
        id: 'setup',
        title: 'Setup',
        icon: <Tool size={20} />,
        // dbId: modules.setup,
        children: [
            // {
            //     id: 'roleAndRight',
            //     title: 'Roles and Rights',
            //     icon: <Circle size={12} />,
            //     navLink: '/setup/role'
            //     // dbId: modules.setup_role
            // },
            {
                id: 'user',
                title: 'User',
                icon: <Circle size={12} />,
                navLink: '/setup/user'
                // dbId: modules.setup_user
            }
        ]
    }
];
