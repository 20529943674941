import { Table } from 'react-feather';

// permission modules
import modules from '@src/service/modules';

export default [
    {
        id: 'table-module',
        title: 'Data Grid',
        icon: <Table size={20} />,
        navLink: '/table'
    }
];
