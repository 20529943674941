import { lazy } from 'react';
import modules from '@src/service/modules';

const ChangePassword = lazy(() => import('../../views/UserProfile/ChangePassword'));
const UserProfile = lazy(() => import('../../views/UserProfile/Profile'));
// const Role = lazy(() => import('../../views/Setup/RoleRights/Role'));
// const Rights = lazy(() => import('../../views/Setup/RoleRights/Rights'));

const ProfileRoutes = [
    {
        path: '/profile/change-password',
        element: <ChangePassword />
    },
    {
        path: '/profile',
        element: <UserProfile />
    }
];

export default ProfileRoutes;
