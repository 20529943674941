// ** Dropdowns Imports
// import CartDropdown from './CartDropdown';
import UserDropdown from './UserDropdown';

// ** Reactstrap Imports
import { NavItem, NavLink } from 'reactstrap';

const NavbarUser = (props) => {
    return (
        <ul className="nav navbar-nav align-items-center ms-auto">
            {/* <IntlDropdown /> */}
            <UserDropdown />
        </ul>
    );
};
export default NavbarUser;
