import { lazy } from 'react';

const Dashboard = lazy(() => import('../../views/Dashboard'));

const DashboardRoutes = [
    {
        path: '/institute',
        element: <Dashboard />
    }
];

export default DashboardRoutes;
