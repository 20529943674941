// ** React Imports
import { Fragment } from 'react';

// ** Third Party Components
import * as Icon from 'react-feather';
// ** Reactstrap Imports
import { NavItem, NavLink } from 'reactstrap';

const NavbarBookmarks = (props) => {
    // ** Props
    const { skin, setSkin, setMenuVisibility } = props;

    // ** Function to toggle Theme (Light/Dark)
    const ThemeToggler = () => {
        if (skin === 'dark') {
            return <Icon.Sun className="ficon" onClick={() => setSkin('light')} />;
        } else {
            return <Icon.Moon className="ficon" onClick={() => setSkin('dark')} />;
        }
    };

    return (
        <Fragment>
            <ul className="navbar-nav d-xl-none">
                <NavItem className="mobile-menu me-auto">
                    <NavLink className="nav-menu-main menu-toggle hidden-xs is-active" onClick={() => setMenuVisibility(true)}>
                        <Icon.Menu className="ficon" />
                    </NavLink>
                </NavItem>
            </ul>
            <ul className="nav navbar-nav bookmark-icons">
                <NavItem className="d-none d-lg-block">
                    <NavLink className="nav-link-style">
                        <ThemeToggler />
                    </NavLink>
                </NavItem>
            </ul>
        </Fragment>
    );
};

export default NavbarBookmarks;
