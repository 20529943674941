import { lazy } from 'react';
import modules from '@src/service/modules';

const User = lazy(() => import('../../views/Setup/User'));
const UserForm = lazy(() => import('../../views/Setup/User/components/Form'));
// const Role = lazy(() => import('../../views/Setup/RoleRights/Role'));
// const Rights = lazy(() => import('../../views/Setup/RoleRights/Rights'));

const SetupRoutes = [
    {
        path: '/setup/user',
        element: <User />
        // dbId: modules.setup_user
    },
    {
        path: '/setup/user/add',
        element: <UserForm />
        // dbId: modules.setup_user_add
    },
    {
        path: '/setup/user/edit/:id',
        element: <UserForm />
        // dbId: modules.setup_user_edit
    }
    // {
    //     path: '/setup/role',
    //     element: <Role />
    //     // dbId: modules.setup_role
    // },
    // {
    //     path: '/setup/role/rights/:id',
    //     element: <Rights />
    //     // dbId: modules.setup_role_assign_permission
    // }
];

export default SetupRoutes;
