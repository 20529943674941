// ** React Imports
import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';

// auth config import
import authConfig from '@src/auth/config.js';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, route }) => {
    // ** Hooks & Vars
    const user = JSON.parse(localStorage.getItem(authConfig.storageTokenKeyName));
    const permissionSlice = useSelector((state) => state?.permission);

    const canView = (id) => {
        if (id) {
            return permissionSlice?.permission?.includes(String(id));
        }
    };

    if (route) {
        let action = null;
        let resource = null;
        let restrictedRoute = false;

        if (route.meta) {
            action = route.meta.action;
            resource = route.meta.resource;
            restrictedRoute = route.meta.restricted;
        }
        if (!user) {
            return <Navigate to="/login" />;
        }
        if (user && restrictedRoute) {
            return <Navigate to="/" />;
        }

        // ToDo - Add logic for blocking route if permission is not given
        if (route?.dbId) {
            if (!canView(route?.dbId)) {
                return <Navigate to="/" />;
            }
        }
        // if (user && restrictedRoute && user.role === 'client') {
        //   return <Navigate to='/access-control' />
        // }
        // if (user && !ability.can(action || 'read', resource)) {
        //   return <Navigate to='/misc/not-authorized' replace />
        // }
    }

    return <Suspense fallback={null}>{children}</Suspense>;
};

export default PrivateRoute;
