import { Settings } from 'react-feather';

// permission modules
import modules from '@src/service/modules';

export default [
    {
        id: 'manage-module',
        title: 'Manage Modules',
        icon: <Settings size={20} />,
        navLink: '/module'
    }
];
