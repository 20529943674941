// ** React Imports
import { Fragment, useEffect, memo } from 'react';

// ** Third Party Components
import classnames from 'classnames';

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux';
import { handleContentWidth, handleMenuCollapsed, handleMenuHidden } from '@store/layout';

// ** ThemeConfig
import themeConfig from '@configs/themeConfig';

// ** Styles
import 'animate.css/animate.css';

const LayoutWrapper = (props) => {
    // ** Props
    const { children, routeMeta } = props;

    // ** Store Vars
    const dispatch = useDispatch();
    const layoutStore = useSelector((state) => state.layout);

    const layoutType = layoutStore.layout;
    const contentWidth = layoutStore.contentWidth;
    //** Vars
    const appLayoutCondition =
        (layoutType.layout === 'horizontal' && !routeMeta) || (layoutType.layout === 'horizontal' && routeMeta && !routeMeta.appLayout);
    const Tag = appLayoutCondition ? 'div' : Fragment;

    // ** Clean Up Function
    const cleanUp = () => {
        if (routeMeta) {
            if (routeMeta.contentWidth && routeMeta.contentWidth === layoutStore.contentWidth) {
                dispatch(handleContentWidth(themeConfig.layout.contentWidth));
            }
            if (routeMeta.menuCollapsed && routeMeta.menuCollapsed === layoutStore.menuCollapsed) {
                dispatch(handleMenuCollapsed(!layoutStore.menuCollapsed));
            }
            if (routeMeta.menuHidden && routeMeta.menuHidden === layoutStore.menuHidden) {
                dispatch(handleMenuHidden(!layoutStore.menuHidden));
            }
        }
    };

    // ** ComponentDidMount
    useEffect(() => {
        if (routeMeta) {
            if (routeMeta.contentWidth) {
                dispatch(handleContentWidth(routeMeta.contentWidth));
            }
            if (routeMeta.menuCollapsed) {
                dispatch(handleMenuCollapsed(routeMeta.menuCollapsed));
            }
            if (routeMeta.menuHidden) {
                dispatch(handleMenuHidden(routeMeta.menuHidden));
            }
        }
        return () => cleanUp();
    }, [routeMeta]);

    return (
        <div
            className={classnames('app-content content overflow-hidden', {
                [routeMeta ? routeMeta.className : '']: routeMeta && routeMeta.className
            })}
        >
            <div className="content-overlay"></div>
            <div className="header-navbar-shadow" />
            <div
                className={classnames({
                    'content-wrapper': routeMeta && !routeMeta.appLayout,
                    'content-area-wrapper': routeMeta && routeMeta.appLayout,
                    'container-xxl p-0': contentWidth === 'boxed'
                })}
            >
                <Tag {...(appLayoutCondition ? { className: 'content-body' } : {})}>{children}</Tag>
            </div>
        </div>
    );
};

export default memo(LayoutWrapper);
