// ** Icons Import
import { Home, Circle } from 'react-feather';

export default [
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: <Home size={20} />,
        navLink: '/dashboard'
    }
];
