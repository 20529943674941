// ** Icons Import
import { Home, Circle } from 'react-feather';
import { Icon } from '@iconify/react';

export default [
    {
        id: 'dashboard',
        title: 'Institute',
        icon: <Icon icon="material-symbols:school-outline" width="30" height="30" />,
        navLink: '/institute'
    }
];
