// ** Menu Components Imports
import HorizontalNavMenuLink from './HorizontalNavMenuLink'
import HorizontalNavMenuGroup from './HorizontalNavMenuGroup'
import {
  resolveHorizontalNavMenuItemComponent as resolveNavItemComponent
} from '@layouts/utils'

const HorizontalNavMenuItems = props => {
  // ** Components Object
  const Components = {
    HorizontalNavMenuGroup,
    HorizontalNavMenuLink
  }

  //   const permissionSlice = useSelector((state) => state?.permission);

  const canView = id => {
    if (id) {
      // return permissionSlice?.permission?.includes(String(id))
    }
  }
  // ** Render Nav Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]

    if (item.children) {
      if (item?.dbId) {
        return canView(item?.dbId) && <TagName item={item} index={index} key={item.id} {...props} />
      }
      return <TagName item={item} index={index} key={item.id} {...props} />
    }

    if (item?.dbId) {
      return canView(item?.dbId) && <TagName key={item.id || item.header} item={item} {...props} />
    }
    return <TagName key={item.id || item.header} item={item} {...props} />
  })

  return RenderNavItems
}

export default HorizontalNavMenuItems
